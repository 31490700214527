<template>
  <a-spin :indicator="indicator" />
</template>
<script>
import { LoadingOutlined } from '@ant-design/icons-vue';
import { defineComponent, h } from 'vue';
export default defineComponent({
  setup() {
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '40px',
      },
      spin: true,
    });
    return {
      indicator,
    };
  },
});
</script>
<style lang="scss">
.anticon-spin {
    color: $color-primary;
}
</style>